<template>
  <div class="tradebox">
    <mobileHeader />
    <div class="banner">
      <img src="../../assets/m_tradebg.jpg" />
      <div
        class="banner-title"
        :class="{ bannerTitleB: $i18n.locale === 'en-US' }"
      >
        {{ $t("lang.trade.bannerTitle") }}
      </div>
    </div>
    <div class="trade">
      <div class="titles">{{ $t("lang.trade.titlesOne") }}</div>
      <div class="parpas">
        {{ $t("lang.trade.parpasOne") }}
      </div>
      <div class="maps">
        <img src="../../assets/maps.png" />
      </div>
      <div class="titles">{{ $t("lang.trade.titlesTwo") }}</div>
      <!--      <div class="parpas">全方位流程化服务 · 精准化人群定位 · 高潜力爆款打造 · 带你掘金全球市场</div>-->
      <div class="parts">
        <div class="partsock">
          <div class="imgs"><img src="../../assets/trade1.png" /></div>
          <div class="title">{{ $t("lang.trade.itemOne.title") }}</div>
          <span class="desc">{{ $t("lang.trade.itemOne.desc") }}</span>
        </div>
        <div class="partsock">
          <div class="imgs"><img src="../../assets/trade2.png" /></div>
          <div class="title">{{ $t("lang.trade.itemTwo.title") }}</div>
          <span class="desc">{{ $t("lang.trade.itemTwo.desc") }}</span>
        </div>
        <div class="partsock">
          <div class="imgs"><img src="../../assets/trade3.png" /></div>
          <div class="title">{{ $t("lang.trade.itemThree.title") }}</div>
          <span class="desc">{{ $t("lang.trade.itemThree.desc") }}</span>
        </div>
      </div>
    </div>
    <mobileFooter />
  </div>
</template>

<script>
import mobileHeader from "@/components/mobileHeader";
import mobileFooter from "@/components/mobileFooter";
export default {
  name: "trade",
  components: {
    mobileHeader,
    mobileFooter,
  },
};
</script>

<style scoped lang="scss">
.tradebox {
  .banner {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .banner-title {
      position: absolute;
      font-size: 30px;
      color: #fff;
      font-weight: bold;
    }
    .bannerTitleB {
      font-family: "English-Bold", serif;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .trade {
    padding: 0 25px 50px;
    margin: 0 auto;
    .titles {
      font-weight: bold;
      font-size: 20px;
      margin-top: 50px;
    }
    .parpas {
      font-size: 16px;
      color: #6d7276;
      margin-top: 10px;
    }
    .maps {
      margin: 0 auto;
      margin-top: 30px;
      img {
        width: 100%;
      }
    }
  }
  .parts {
    display: flex;
    flex-direction: column;
    align-items: center;
    .partsock {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
      .imgs {
        margin: 30px 0 20px;
        width: 70px;
        img {
          width: 100%;
        }
      }
      .title {
        font-size: 20px;
        padding-bottom: 10px;
        text-align: center;
      }
      span {
        font-size: 14px;
        line-height: 25px;
        width: 200px;
        color: #6d7276;
        text-align: center;
      }
    }
  }
}
</style>
